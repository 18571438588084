@use "../assets/styles/mixins" as mixins;

.footer {
    width: 100%;
    height: 160px;
    background-color: var(--background-color-1);
    border-top: 2px solid #1e0171;
    padding: 20px;

    @include mixins.md-lt {
        height: 250px;
        flex-direction: column;
        justify-content: center;
    }

    .icon_footer {
        width: 40px;
        height: 40px;
        margin-left: 10px;

        &:hover {
            opacity: 0.5;
        }

        @include mixins.md-lt {
            width: 30px;
            height: 30px;
        }

        @include mixins.xs {
            width: 20px;
            height: 20px;
        }
    }

    a,
    .mail {
        font-size: 14px;

        @include mixins.xs {
            font-size: 10px;
        }

        &:hover {
            opacity: 0.5;
        }
    }
}

.copyright {
    font-family: var(--font-family-1);
    font-size: 6px;
    color: #1e0171;
    padding-right: 30px;

    @include mixins.md-lt {
        padding-right: 0;
    }
}