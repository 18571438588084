@use "../assets/styles/mixins" as mixins;

.bannerprojects {
    .img5 {
        position: absolute;
        width: 100%;
        height: 400px;
        border-bottom: 2px solid #1e0171;

        @include mixins.sm-lt {
            height: 200px;
        }
    }

    h1 {
        position: relative;
        display: flex;
        justify-content: space-between;
        text-align: center;
        line-height: 1.3;
        margin: 0;
        padding-top: 130px;
        padding-left: 20px;

        @include mixins.sm-lt {
            padding-top: 60px;
            padding-left: 10px;
        }

        @include mixins.xxs {
            font-size: 14px;
        }
    }
}