@use "../styles/mixins" as mixins;

* {
    box-sizing: border-box;
}

body {
    font-family: var(--font-family-1);
    font-family: var(--font-family-2);
    background: var(--background);
    margin: 0 auto;
}

main {
    margin: auto;
}

ul {
    list-style: none;
    padding: 0;
}

a {
    font-family: var(--font-family-1);
    font-size: 26px;
    font-weight: 500;
    text-decoration: none;
    color: var(--text-color-1);

    @include mixins.sm-lt {
        font-size: 16px;
    }

    @include mixins.xs {
        font-size: 10px;
    }
}

a.nav-active {
    text-decoration: underline;
    text-underline-offset: 15px;
    text-decoration-color: #2ec0f2;
    text-decoration-thickness: 2px;
    margin-left: 35px;

    @include mixins.sm-lt {
        font-size: 16px;
        margin-left: 20px;
    }

    @include mixins.xs {
        font-size: 14px;
        margin-left: 8px;
    }

    @include mixins.xxs {
        font-size: 10px;
        margin-left: 8px;
    }
}

a.nav-active:hover {
    color: #2ec0f2;
    transition: color .3s;
}

a.nav-inactive {
    position: relative;
    margin-left: 35px;

    @include mixins.sm-lt {
        font-size: 16px;
        margin-left: 20px;
    }

    @include mixins.xs {
        font-size: 14px;
        margin-left: 8px;
    }

    @include mixins.xxs {
        font-size: 10px;
        margin-left: 8px;
    }
}

a.nav-inactive:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 50%;
    bottom: -9px;
    background-color: #2ec0f2;
    transform: translateX(-50%) scaleX(0);
    transform-origin: 50% 50%;
    transition: transform 300ms;
}

a.nav-inactive:hover {
    color: #2ec0f2;
    transition: color .3s;
}

a.nav-inactive:hover:after {
    transform: translateX(-50%) scaleX(1);
}

h1 {
    font-family: var(--font-family-2);
    font-size: 35px;
    color: var(--text-color-2);

    @include mixins.lg-lt {
        font-size: 30px;
    }

    @include mixins.md-lt {
        font-size: 26px;
    }

    @include mixins.sm-lt {
        font-size: 22px;
    }

    @include mixins.xs {
        font-size: 18px;
    }

    @include mixins.xxs {
        font-size: 16px;
    }
}

h2 {
    font-family: var(--font-family-2);
    font-size: 30px;
    color: var(--text-color-2);

    @include mixins.xs {
        font-size: 20px;
    }
}

h3 {
    font-family: var(--font-family-2);
    font-size: 24px;
    color: var(--text-color-3);

    @include mixins.md {
        font-size: 20px;
    }

    @include mixins.sm {
        font-size: 18px;
    }

    @include mixins.xs {
        font-size: 16px;
    }
}

h4 {
    font-family: var(--font-family-2);
    font-size: 26px;
    color: var(--text-color-2);

    @include mixins.md {
        font-size: 22px;
    }

    @include mixins.sm {
        font-size: 20px;
    }

    @include mixins.xs {
        font-size: 18px;
    }
}

img {
    object-fit: cover;
}