@use "../assets/styles/mixins" as mixins;

.containerprojects {
    display: grid;
    place-content: center;
    grid-template-columns: repeat(3, 350px);
    gap: 30px;
    padding-top: 260px;
    padding-bottom: 30px;

    @include mixins.lg-lt {
        grid-template-columns: repeat(2, 350px);
        gap: 50px;
    }

    @include mixins.sm-lt {
        grid-template-columns: repeat(1, 300px);
        gap: 30px;
        padding-top: 115px;
    }

    @include mixins.xxs {
        padding-top: 130px;
    }

    .cards {
        width: 100%;
        background-color: #d3d2d5;
        border: 1px solid #1e0171;
        border-radius: 5px;
        box-shadow: rgb(30, 1, 113) 0 5px 15px;
        padding: 15px;

        .icons {
            width: 100px;
            margin-left: 120px;

            @include mixins.sm-lt {
                width: 80px;
                margin-left: 100px;
            }
        }

        .iconvitalguard {
            width: 100px;
            margin-left: 110px;
            margin-top: 40px;

            @include mixins.sm-lt {
                margin-left: 85px;
            }
        }

        .title_projects {
            text-align: center;
        }

        .p_projects {
            font-family: var(--font-family-1);
            text-align: center;
            color: var(--text-color-2);

            @include mixins.sm {
                font-size: 14px;
            }

            @include mixins.xs {
                font-size: 12px;
            }
        }
    }
}

.container {
    width: 100%;
    height: 150px;
    background-color: var(--background-color-2);

    @include mixins.xxs {
        height: 190px;
    }

    .title_container {
        text-align: center;
        margin-bottom: 10px;
        padding-top: 30px;

        @include mixins.xs {
            font-size: 16px;
        }
    }

    .p_container {
        font-family: var(--font-family-1);
        text-align: center;
        color: var(--text-color-3);

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 12px;
        }
    }
}

.p_request {
    font-family: var(--font-family-2);
    text-align: center;
    color: #1e0171;
    margin-top: 30px;

    @include mixins.sm {
        font-size: 14px;
    }

    @include mixins.xs {
        font-size: 12px;
    }
}

a,
.mail {
    font-size: 18px;

    @include mixins.md {
        font-size: 16px;
    }

    @include mixins.sm {
        font-size: 14px;
    }

    @include mixins.xs {
        font-size: 12px;
    }

    &:hover {
        opacity: 0.5;
    }
}