@use "../assets/styles/mixins" as mixins;

.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;

    @include mixins.xs {
        margin-bottom: 60px;
    }

    img,
    .error404 {

        @include mixins.sm-lt {
            width: 200px;
        }
    }
}

.subtitleerror {
    font-family: var(--font-family-1);
    font-size: 36px;
    color: var(--text-color-1);
    margin-top: 0;
    margin-bottom: 100px;

    @include mixins.lg-lt {
        font-size: 28px;
    }

    @include mixins.md-lt {
        font-size: 24px;
    }

    @include mixins.sm-lt {
        font-size: 18px;
    }

    @include mixins.xs {
        font-size: 14px;
        margin-bottom: 70px;
    }

    @include mixins.xxs {
        font-size: 10px;
    }
}

.link a,
.btn {
    background-color: #0176c5;
    color: var(--text-color-1);
    font-family: var(--font-family-2);
    font-size: 22px;
    text-decoration: none;
    border: 3px solid #ffffff;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;

    @include mixins.sm {
        font-size: 18px;
    }

    @include mixins.xs {
        font-size: 14px;
    }

    &:hover {
        background-color: #ffffff;
        color: var(--text-color-2);
        border: 3px solid #0176c5;
        box-shadow: 8px 5px 2px rgb(1, 118, 197);
        transform: scale(1.03);
        transition: 0.3s;
    }
}