@use "../assets/styles/mixins" as mixins;

.header {
    width: 100%;
    height: 150px;
    background-color: var(--background-color-1);
    border-bottom: 2px solid #1e0171;
    padding: 20px;

    .logoheader {

        &:hover {
            opacity: 0.5;
        }
    }

    @include mixins.md-lt {
        flex-direction: column;
        justify-content: center;
        height: 250px;
    }
}