.d-flex {
    display: flex;
}

.d-grid {
    display: grid;
}

.d-none {
    display: none;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-fill {
    flex: 1 1 auto;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-flex-start {
    justify-content: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-end {
    align-items: end;
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}