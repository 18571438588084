@use "../assets/styles/mixins" as mixins;

.banneraboutus {
    .img3 {
        position: absolute;
        width: 100%;
        height: 450px;
        border-bottom: 2px solid #1e0171;

        @include mixins.sm-lt {
            height: 250px;
        }
    }

    h1 {
        position: relative;
        display: flex;
        justify-content: end;
        text-align: center;
        line-height: 1.5;
        color: var(--text-color-1);
        margin: 0;
        padding-top: 180px;
        padding-right: 80px;

        @include mixins.sm-lt {
            padding-top: 100px;
            padding-right: 30px;
        }
    }
}