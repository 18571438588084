@use "../assets/styles/mixins" as mixins;

.bannerhome {
    .img1 {
        position: absolute;
        width: 100%;
        height: 450px;
        border-bottom: 2px solid #1e0171;

        @include mixins.sm-lt {
            height: 250px;
        }
    }

    h1 {
        position: relative;
        display: flex;
        justify-content: space-between;
        text-align: center;
        line-height: 1.5;
        margin: 0;
        padding-top: 180px;
        padding-left: 80px;

        @include mixins.sm-lt {
            padding-top: 100px;
        }

        @include mixins.xxs {
            padding-left: 20px;
        }
    }
}