@use "../assets/styles/mixins" as mixins;

h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
}

.p_mobiledevelopment {
    font-family: var(--font-family-1);
    text-align: center;
    color: var(--text-color-1);
    padding: 10px 40px 50px 40px;

    @include mixins.sm {
        font-size: 14px;
        padding: 10px 40px 0 40px;
    }

    @include mixins.xs {
        font-size: 12px;
        padding: 10px 40px 0 40px;
    }
}

.opsys {
    padding: 20px 0 20px 0;

    @include mixins.md-lt {
        width: 70%;
    }
}

.phone {
    padding: 30px 0 30px 0;

    @include mixins.md-lt {
        width: 50%;
    }

    @include mixins.xs {
        padding: 0
    }
}

.title_app {
    text-align: center;
}

.p_app {
    font-family: var(--font-family-1);
    text-align: center;
    color: var(--text-color-1);
    padding: 0 50px 30px 50px;

    @include mixins.sm {
        font-size: 14px;
    }

    @include mixins.xs {
        font-size: 12px;
    }
}

.container {
    width: 100%;
    height: 150px;
    background-color: var(--background-color-2);

    @include mixins.xxs {
        height: 190px;
    }

    .title_container {
        text-align: center;
        margin-bottom: 10px;
        padding-top: 30px;

        @include mixins.xs {
            font-size: 16px;
        }
    }

    .p_container {
        font-family: var(--font-family-1);
        text-align: center;
        color: var(--text-color-3);

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 12px;
        }
    }
}