@use "../assets/styles/mixins" as mixins;

.container {
    width: 100%;
    height: 150px;
    background-color: var(--background-color-2);

    @include mixins.sm-lt {
        height: 130px;
    }

    @include mixins.xs {
        height: 100px;
    }

    .title_container {
        text-align: center;
        margin: 0;
        padding-top: 50px;

        @include mixins.xs {
            padding-top: 40px;
        }
    }
}

.contactus {
    .contact {
        font-family: var(--font-family-2);
        font-size: 26px;
        text-align: center;
        color: #1e0171;
        margin-top: 50px;

        @include mixins.sm {
            font-size: 20px;
        }

        @include mixins.xs {
            font-size: 16px;
        }
    }

    .address {
        font-family: var(--font-family-1);
        text-align: center;
        color: #1e0171;

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 12px;
        }
    }

    .phonenumber {
        font-family: var(--font-family-1);
        text-align: center;
        color: #1e0171;
        margin-bottom: 40px;

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 12px;
        }
    }

    a,
    .mail {
        font-size: 20px;

        @include mixins.md {
            font-size: 16px;
        }

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 10px;
        }

        &:hover {
            opacity: 0.5;
        }
    }

    .map {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}