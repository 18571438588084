@use "../assets/styles/mixins" as mixins;

.about {
    padding-top: 200px;

    @include mixins.sm-lt {
        padding-top: 100px;
    }


    .aboutFdev {
    max-width: 800px; /* Limit the width of the text block */
    margin: 0 auto; /* Center the block */
    padding: 20px; /* Add some padding */
    text-align: justify; /* Justify the text */
}

.p_aboutus {
    line-height: 1.6; /* Increase line spacing for readability */
    font-size: 1rem; /* Ensure the text is a reasonable size */
    margin-bottom: 20px; /* Space between paragraphs */
}

.mail {
    display: inline-block;
    margin-top: 20px;
    font-weight: bold;
    color: #007BFF;
    text-decoration: none;
}

.mail:hover {
    text-decoration: underline;
}

/* Responsive design adjustments */
@media (max-width: 600px) {
    .aboutFdev {
        padding: 10px; /* Reduce padding on smaller screens */
        font-size: 0.9rem; /* Slightly reduce text size on smaller devices */
    }

    .p_aboutus {
        font-size: 0.9rem;
    }
}


    // .aboutFdev {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
    // }

    .imgfab {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-top: 40px;

        @include mixins.xs {
            width: 100px;
            height: 100px;
        }

        @include mixins.xs {
            width: 80px;
            height: 80px;
        }
    }

    .name {
        color: var(--text-color-2);
        text-align: center; /* Centre le titre */
        margin-bottom: 20px; /* Ajoute un espace sous le titre */
    }

    .p_aboutus {
        font-family: var(--font-family-1);
        text-align: justify;
        color: #1e0171;
        padding-left: 5px;

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 10px;
            padding-left: 30px;
        }
    }

    a,
    .mail {
        margin-left: 0;
        font-size: 20px;
        text-align: center;

        @include mixins.md {
            font-size: 16px;
        }

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 10px;
        }

        &:hover {
            opacity: 0.5;
        }
    }
}

.diploma {
    display: flex;
    flex-wrap: wrap; /* Permet aux éléments de passer à la ligne quand nécessaire */
    justify-content: space-between; /* Distribue les éléments sur la ligne */
}

.certification1, .certification2, .certification3, .certification4, .certification5 {
    width: 20%; /* Chaque diplôme prendra environ 22% de la largeur pour qu'il y ait 4 par ligne */
    height: auto;
    margin: 10px 0;
    
    @include mixins.md-lt {
        width: 45%; /* Sur les écrans plus petits, les diplômes seront à 2 par ligne */
    }

    @include mixins.xs {
        width: 100%; /* Sur les très petits écrans, un seul diplôme par ligne */
    }
}

.p_certification {
    font-family: var(--font-family-1);
    font-size: 14px;
    color: var(--text-color-2);
    margin: 0;
    padding: 0 5px;

    @include mixins.md-lt {
        text-align: center;
    }

    @include mixins.sm {
        font-size: 14px;
    }

    @include mixins.xs {
        font-size: 12px;
    }

    @include mixins.xxs {
        font-size: 10px;
    }
}

h2 {
    padding-top: 30px;
}

/* Force les deux derniers éléments à aller en dessous */
.certification-group:last-child {
    flex-basis: 100%; /* Occupe toute la largeur pour aller sur une nouvelle ligne */
    display: flex;
    justify-content: center; /* Centre les éléments à l'intérieur de cette ligne */
}
.containerskills {
    display: grid;
    place-content: center;
    grid-template-columns: repeat(3, 350px);
    gap: 60px;
    padding-top: 30px;
    padding-bottom: 30px;

    @include mixins.lg-lt {
        grid-template-columns: repeat(2, 350px);
        gap: 50px;
    }

    @include mixins.sm-lt {
        grid-template-columns: repeat(1, 300px);
        gap: 30px;
    }

    @include mixins.sm-lt {
        padding-top: 30px;
    }

    .cards {
        width: 100%;
        background-color: #d3d2d5;
        border: 1px solid #1e0171;
        border-radius: 5px;
        box-shadow: rgb(30, 1, 113) 0 5px 15px;
        padding: 15px;

        .icons {
            width: 60px;
            height: 60px;
            margin-left: 130px;

            @include mixins.sm-lt {
                margin-left: 105px;
            }
        }

        .title_competence {
            text-align: center;
        }

        .p_competence {
            font-family: var(--font-family-1);
            text-align: center;
            color: var(--text-color-2);

            @include mixins.sm {
                font-size: 14px;
            }

            @include mixins.xs {
                font-size: 12px;
            }
        }
    }
}

.container {
    width: 100%;
    height: 150px;
    background-color: var(--background-color-2);

    @include mixins.xxs {
        height: 190px;
    }

    .title_container {
        text-align: center;
        margin-bottom: 10px;
        padding-top: 30px;

        @include mixins.xs {
            font-size: 16px;
        }
    }

    .p_container {
        font-family: var(--font-family-1);
        text-align: center;
        color: var(--text-color-3);

        @include mixins.sm {
            font-size: 14px;
        }

        @include mixins.xs {
            font-size: 12px;
        }
    }
}