@use "../assets/styles/mixins" as mixins;

.containerhome {
    display: grid;
    place-content: center;
    grid-template-columns: repeat(3, 350px);
    gap: 20px;
    padding-top: 250px;

    @include mixins.lg-lt {
        grid-template-columns: repeat(2, 350px);
        gap: 50px;
    }

    @include mixins.sm-lt {
        grid-template-columns: repeat(1, 300px);
        gap: 30px;
    }

    @include mixins.sm-lt {
        padding-top: 150px;
    }

    .cards {
        width: 100%;
        background-color: #d3d2d5;
        border: 1px solid #1e0171;
        border-radius: 5px;
        box-shadow: rgb(30, 1, 113) 0 5px 15px;
        padding: 15px;

        .icons {
            width: 50px;
            height: 50px;
            margin-left: 138px;

            @include mixins.sm-lt {
                margin-left: 110px;
            }
        }

        .title_icons {
            text-align: center;
        }

        .paragraph {
            font-family: var(--font-family-1);
            text-align: center;
            color: var(--text-color-2);

            @include mixins.sm {
                font-size: 14px;
            }

            @include mixins.xs {
                font-size: 12px;
            }
        }
    }
}