.cube {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 5px;
    height: 5px;
    border: solid 1px #0176c5;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
}

.cube:nth-child(2n) {
    border-color: #ffffff;
}

.cube:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 40vh;
}

.cube:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 40vh;
}

.cube:nth-child(4) {
    animation-delay: 6s;
    left: 75vw;
    top: 120vh;
}

.cube:nth-child(5) {
    animation-delay: 8s;
    left: 20vw;
    top: 120vh;
}

.cube:nth-child(6) {
    animation-delay: 10s;
    left: 50vw;
    top: 10vh;
}

@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }

    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}